import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';

const Wrapper = styled.div`
  h2 {
    font-size: 3rem;
    font-weight: 900;
  }
`;

export interface Data {
  allMarkdownRemark: AllMarkdownRemark;
}

export interface AllMarkdownRemark {
  nodes: Node[];
}

export interface Node {
  fields: Fields;
  html: string;
}

export interface Fields {
  slug: string;
}

const Welcome = () => {
  const pageQuery: Data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "welcome" } } }) {
        nodes {
          fields {
            slug
          }
          html
        }
      }
    }
  `);
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: pageQuery.allMarkdownRemark.nodes[0].html }} />
      <p>Want to get an overview of MOMENTVM as a CMS?</p>
      <Link to="/get-started/at-a-glance">Start here</Link>
      <p>Already signed up and want to set it up?</p>
      <Link to="/get-started/setup-momentvm/">Start with the configuration</Link>
    </Wrapper>
  );
};

export default Welcome;
