import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Welcome from '../components/Welcome';

export interface Data {
  site: Site;
  allMarkdownRemark: AllMarkdownRemark;
}

export interface AllMarkdownRemark {
  edges: Edge[];
}

export interface Edge {
  node: Node;
}

export interface Node {
  excerpt: string;
  fields: Fields;
  frontmatter: Frontmatter;
}

export interface Fields {
  slug: string;
}

export interface Frontmatter {
  date: string;
  title: string;
}

export interface Site {
  siteMetadata: SiteMetadata;
}

export interface SiteMetadata {
  title: string;
}

const StyledLink = styled(Link)``;

const Wrapper = styled.div``;

interface Props {
  data: Data;
  location: Location;
}

const BlogIndex = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'MOMENTVM Content Cloud CMS for Salesforce Commerce Cloud' },
          { name: 'google-site-verification', content: '1_y9BddNhzZTZanD4lCsbzyJ1L3TrSwkrbvfPOzplrQ' },
        ]}
      />
      <Wrapper>
        <Layout location={location} title={siteTitle}>
          <SEO title="All posts" />
          <Welcome />
        </Layout>
      </Wrapper>
    </>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
